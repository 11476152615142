@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: Camp-Bold;
  src: url('./fonts/CamphorProForXiaoMi-Bold.woff');
}

@font-face {
  font-family: Camp-Heavy;
  src: url('./fonts/CamphorProForXiaoMi-Heavy.woff');
}

@font-face {
  font-family: Camp-Light;
  src: url('./fonts/CamphorProForXiaoMi-Light.woff');
}

@font-face {
  font-family: Camp-Medium;
  src: url('./fonts/CamphorProForXiaoMi-Medium.woff');
}

@font-face {
  font-family: Camp-Regular;
  src: url('./fonts/CamphorProForXiaoMi-Regular.woff');
}

@font-face {
  font-family: Camp-Thin;
  src: url('./fonts/CamphorProForXiaoMi-Thin.woff');
}

@font-face {
  font-family: press;
  src: url('./fonts/PrStart.woff');
}

@font-face {
  font-family: pressTK;
  src: url('./fonts/prstartk.woff');
}

:root {
  --color-main: #ff6700;
  --color-darkMain: #b44800;
  --color-tyc: #ff6700;
  --color-textDark: #b44800;
  --color-tycText: #F5F5F5;
}

body {
  margin: 0;
  padding: 0;
  font-family: Camp-Regular;
  color: #F5F5F5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}


body #root {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background: #5554;
}
*::-webkit-scrollbar-thumb {
  background: var(--color-main);
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb:hover {
  background: var(--color-darkMain);
}

.swal2-popup {
  border-radius: 20px !important;
}

.swal2-title {
  padding: 0px !important;
}

.swal2-title br {
  font-size: 0 !important;
}

.swal2-image {
  margin: 3vw auto 1vw auto !important;
}

.closePopUp {
  position: absolute;
  top: 0%;
}

button.swal2-close {
  color: #F5F5F5;
}

/* ---------------------------- */

.preloader {
  width: 40px;
  height: 40px;

  border: 5px solid transparent;
  border-top: 5px solid var(--color-main);
  border-left: 5px solid var(--color-main);
  border-bottom: 5px solid var(--color-main);
  border-radius: 50%;

  margin: 5px auto;

  animation-name: girar;
  animation-duration: .9s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes girar {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ----------------------------------------- */

/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

